<template>
  <v-card>
    <v-row class="pa-4" align="center">
      <template v-for="(layout, i) in layouts">
        <v-col :key="i" cols="12" lg="4" md="6">
          <div class="overline text-center">{{ layout.label }}</div>
          <v-hover v-slot="{ hover }">
            <v-card
              :class="{ 'on-hover': hover }"
              :elevation="hover ? 12 : 2"
              @click="updateSkin(layout.id)"
            >
              <v-img
                :aut-image-layout="layout.id"
                outline
                :src="layout.img"
              ></v-img>
              <v-fade-transition>
                <v-overlay
                  v-if="currentLayout != layout.id ? hover : true"
                  absolute
                  color="primary"
                >
                  <v-btn
                    v-if="currentLayout != layout.id"
                    @click="updateSkin(layout.id)"
                    >Select Layout</v-btn
                  >
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <FeatureSwitches
          :features="layoutFeatures"
          @update="updateSettings"
          :key="refreshKey"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { set } from "lodash";
import { mapState, mapActions } from "vuex";
import FeatureSwitches from "@/views/administration/FeatureFlags/FeatureSwitches/FeatureSwitches.vue";
import { layoutFeatures } from "@/settings";
import { LAYOUTS } from "@/constants.js";
export default {
  name: "SettingsLayout",
  props: {
    definition: {
      type: Object,
    },
  },
  data() {
    const layImg = {
      classic: "/layouts/classic.png",
      twoPanel: "/layouts/two_panel.png",
      threePanel: "/layouts/three_panel.png",
    };
    const val = Object.values(layImg);
    const layouts = Object.keys(LAYOUTS).map((key, index) => {
      return {
        label: key,
        id: LAYOUTS[key],
        img: val[index],
      };
    });
    return {
      refreshKey: 1,
      layoutFeatures,
      layouts,
    };
  },
  computed: {
    ...mapState(["skin"]),
    currentLayout() {
      return this.skin.layout?.value || LAYOUTS.CLASSIC;
    },
  },
  components: {
    FeatureSwitches,
  },
  methods: {
    ...mapActions(["saveSettingsToDomain"]),
    updateSettings({ name, value }) {
      const settings = {};
      set(settings, name, value);
      this.saveSettingsToDomain(settings);
    },
    updateSkin(layout) {
      if (layout == this.currentLayout) {
        return;
      }
      let settings = {
        layout: {
          value: layout,
          left_panel: {},
          main_panel: {},
          right_panel: {},
        },
      };
      switch (layout) {
        case LAYOUTS.CLASSIC:
          settings.layout.left_panel.size = "0";
          settings.layout.main_panel.size = "12";
          settings.layout.right_panel.size = "0";
          break;
        case LAYOUTS.TWO_PANEL:
          settings.layout.left_panel.size = "4";
          settings.layout.main_panel.size = "8";
          settings.layout.right_panel.size = "0";
          break;
        case LAYOUTS.THREE_PANEL:
          settings.layout.left_panel.size = "3";
          settings.layout.main_panel.size = "6";
          settings.layout.right_panel.size = "3";
          break;
        default: {
          settings.layout.left_panel.size = "0";
          settings.layout.main_panel.size = "12";
          settings.layout.right_panel.size = "0";
        }
      }
      this.saveSettingsToDomain(settings);
      this.refreshKey++;
    },
  },
};
</script>
